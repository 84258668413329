import {
  GATSBY_CONF_MAPBOX_ACCESS_TOKEN,
  GATSBY_SOURCE_SUSHISHOP_BASE_URL,
} from "@src/utils/constants";

export const appConfig = {
  mapboxAccessToken: GATSBY_CONF_MAPBOX_ACCESS_TOKEN,
  sushishopBaseUrl: GATSBY_SOURCE_SUSHISHOP_BASE_URL,
  enableInternationalRouting: process.env.GATSBY_INTERNATIONAL_ROUTING || false,
  forceRollingStartRouting:
    process.env.GATSBY_FORCE_ROLLING_START_ROUTING || false,
};
