import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { _SelectInputWrapper, _SelectRoot } from "./Select.styled";
import Option from "./Option/Option";
import Icon from "../../Foundation/Icon/Icon";
import Typography from "../../Foundation/Typography/Typography";
import { useTheme } from "../../Themes/defaultTheme";
import FlexBox from "../../Components/FlexBox/FlexBox";
import Dropdown, { IDropdownProps } from "../../Core/Dropdown/Dropdown";
import Spinner from "../../Foundation/Spinner/Spinner";

type ISelectOption<V, L extends ReactNode = ReactNode> = {
  label: L;
  value?: V;
  groups?: ISelectOption<V, L>[];
};

export enum ESelectSize {
  SMALL = "small",
  MEDIUM = "medium",
  COMPACT = "compact",
}

export interface ISelectProps<V, L extends ReactNode = ReactNode> {
  options: ISelectOption<V, L>[];
  value?: V;
  placeholder?: string;
  customInput?: (selectedValue?: L) => ReactNode;
  style?: CSSProperties;
  onChange?: (value: V) => void;
  size?: ESelectSize;
  placement?: IDropdownProps["placement"];
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  disableIconAnimation?: boolean;
}

const Select = <T extends string | number | boolean, L extends ReactNode>(
  props: ISelectProps<T, L>
) => {
  const {
    options,
    value,
    style,
    size = ESelectSize.MEDIUM,
    customInput,
    onChange,
    placement,
    disabled,
    className,
    loading,
    disableIconAnimation,
  } = props;

  // ref
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  // states
  const [selectedOption, setSelectedOption] = useState<ISelectOption<T, L>>();
  const [show, setShow] = useState<boolean>(false);
  const [parentWidth, setParentWidth] =
    useState<CSSProperties["width"]>("auto");

  const { color } = useTheme();
  const inputWrapperRef = useRef<HTMLDivElement | null>(null);

  const handleSelect = (option: ISelectOption<T, L>) => () => {
    if (option.value) {
      setSelectedOption(option);
      setShow(false);
      onChange && onChange(option.value);
    }
  };
  const findOption = (
    options: ISelectOption<T, L>[],
    value: T
  ): ISelectOption<T, L> | undefined => {
    for (const option of options) {
      if ("value" in option && option.value === value) {
        return option;
      } else if ("groups" in option && option.groups) {
        const found = findOption(option.groups, value);
        if (found) return found;
      }
    }
    return undefined;
  };

  const selectedValue = useMemo((): L | undefined => {
    if (typeof value === "undefined") {
      return undefined;
    }
    const optionValue = findOption(options, value);
    const v = !!optionValue ? optionValue.label : value + "";
    return v as L;
  }, [value, options]);

  const getWrapperWidth = () => {
    if (inputWrapperRef.current) {
      const rect = inputWrapperRef.current.getBoundingClientRect();
      if (rect.width) {
        setParentWidth(rect.width);
      }
    }
  };

  const handleOnVisibleChange = (visible: boolean) => {
    if (!disabled) {
      setShow(visible);
      getWrapperWidth();
    }
  };

  // ---------- effect -------
  useEffect(() => {
    getWrapperWidth();

    window.addEventListener("resize", getWrapperWidth);
    return () => {
      window.removeEventListener("resize", getWrapperWidth);
    };
  }, []);

  useEffect(() => {
    if (typeof value !== "undefined") {
      const defaultSelectedOption = findOption(options, value);
      if (defaultSelectedOption) {
        setSelectedOption(defaultSelectedOption);
      }
    }
  }, [value, selectedOption, options]);

  return (
    <_SelectRoot {...{ className, style }} ref={wrapperRef}>
      <Dropdown
        contentStyle={{
          padding: "20px 22px 20px 20px",
          minWidth: parentWidth,
          maxHeight: 150,
        }}
        popupVisible={show}
        placement={placement}
        content={options.map((option) => {
          if (option.groups) {
            return (
              <div key={JSON.stringify(option.label)}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                  color={() => color.placeholder}
                  variant="body2"
                >
                  {option.label}
                </Typography>
                {option.groups.map((groupOption) => {
                  const { value, label } = groupOption;
                  return (
                    <Option
                      key={JSON.stringify(value)}
                      value={value}
                      onClick={handleSelect(groupOption)}
                    >
                      <Typography
                        style={{
                          fontWeight:
                            selectedOption && selectedOption.value === value
                              ? "bold"
                              : undefined,
                          whiteSpace: "nowrap",
                        }}
                        color={() => color.placeholder}
                        variant="body2"
                      >
                        {label}
                      </Typography>
                    </Option>
                  );
                })}
              </div>
            );
          } else {
            const { value, label } = option;
            return (
              <Option
                key={JSON.stringify(value)}
                value={value}
                onClick={handleSelect(option)}
              >
                <Typography
                  style={{
                    fontWeight:
                      selectedOption && selectedOption.value === value
                        ? "bold"
                        : undefined,
                    whiteSpace: "nowrap",
                  }}
                  color={() => color.placeholder}
                variant={"body2"}
                >
                  {label}
                </Typography>
              </Option>
            );
          }
        })}
        onVisibleChange={handleOnVisibleChange}
      >
        {customInput ? (
          <div ref={inputWrapperRef} onClick={(e) => e.stopPropagation()}>
            {customInput(selectedValue)}
          </div>
        ) : (
          <_SelectInputWrapper
            ref={inputWrapperRef}
            onClick={(e) => e.stopPropagation()}
            $size={size}
            $loading={loading}
          >
            <Typography
              color={() => color.placeholder}
              variant={size === ESelectSize.SMALL ? "body2" : "body"}
              style={{
                minWidth: ".6em",
                textAlign: "center",
                marginTop: "-0.1em",
              }}
            >
              {selectedValue}
            </Typography>
            <FlexBox
              style={{
                marginLeft: "auto",
                justifyContent: "center",
                alignItems: "center",
                transform: disableIconAnimation
                  ? undefined
                  : show
                  ? "rotate(-360deg)"
                  : "rotate(0deg)",
                transition: "all 0.5s ease-in-out",
              }}
            >
              <Icon
                icon={
                  size === ESelectSize.COMPACT
                    ? "caret_down_bold_16"
                    : "caret_down_16"
                }
                size={16}
                color={color.placeholder}
              />
            </FlexBox>
            {loading && <Spinner absolute={true} />}
          </_SelectInputWrapper>
        )}
      </Dropdown>
    </_SelectRoot>
  );
};

export default Select;
