import { RollingStartEndpointBuilder } from "..";

export interface IZendeskContactBody {
  subject?: string;
  form_id?: number;
  image?: FileList;
  [_: string]: any;
}

export const createZendeskEndpoints = (
  builder: RollingStartEndpointBuilder
) => ({
  postZendeskContact: builder.mutation<
    { id_image?: string } | Array<string | number>,
    {
      body: IZendeskContactBody | FormData;
      lang: string;
      upload?: boolean;
    }
  >({
    query: ({ body, lang, upload }) => {
      return {
        url: `/${lang || "fr"}/zendeskcontact`,
        method: "POST",
        params: {
          upload: upload ? "true" : undefined,
        },
        body,
      };
    },
  }),
  getZendeskSearch: builder.query<
    {
      count: number;
      next_page: number | null;
      page: number;
      page_count: number;
      per_page: number;
      previous_page: number | null;
      results: Array<{
        author_id: number;
        body: string;
        comments_disabled: boolean;
        created_at: string;
        draft: boolean;
        edited_at: string;
        html_url: string;
        id: number;
        label_names: string[];
        locale: string;
        name: string;
        outdated: boolean;
        outdated_locales: any[];
        permission_group_id: number;
        position: number;
        promoted: boolean;
        result_type: string;
        section_id: number;
        snippet: string;
        source_locale: string;
        title: string;
        updated_at: string;
        url: string;
        user_segment_id: number | null;
        vote_count: number;
        vote_sum: number;
      }>;
    },
    {
      query: string;
      baseUrl?: string;
    }
  >({
    query: ({ query, baseUrl }) => {
      return {
        url: "/help_center/articles/search.json",
        baseUrl,
        method: "GET",
        params: {
          query,
        },
      };
    },
  }),
});
