import { CustomerData } from "@src/interface/Customer";
import { setCurrentCustomer } from "@src/store/reducers/CustomerSlice";
import { RollingStartEndpointBuilder } from "..";

type IContactBody = any;

export const createContactEndpoints = (
  builder: RollingStartEndpointBuilder
) => ({
  updateContact: builder.mutation<
    CustomerData,
    {
      id_contact?: string;
      body: IContactBody;
    }
  >({
    query: ({ id_contact = "new", body }) => ({
      url: `/contact/${id_contact}`,
      method: "PUT",
      body,
    }),
  }),
});
