import { useEffect } from "react";

const useAutoResizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
  onChange?: (height: number) => void
) => {
  useEffect(() => {
    if (textAreaRef) {
      // Reset the height to calculate the new scrollHeight
      textAreaRef.style.height = "auto";
      textAreaRef.style.height = `${textAreaRef.scrollHeight}px`;
      onChange?.(textAreaRef.scrollHeight);
    }
  }, [textAreaRef, value, onChange]);
};

export default useAutoResizeTextArea;
