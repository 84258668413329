import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { createApi } from "@reduxjs/toolkit/query/react";
import { IAddressData } from "@src/interface/Address";
import { IFrontController } from "@src/interface/HybridRouting";
import { setAddresses } from "@src/store/reducers/AddressSlice";
import { setFrontController } from "@src/store/reducers/HybridRoutingSlice";
import { baseQueryFn } from "./baseQuery";
import { createAuthEndpoints } from "./endpoints/auth.endpoints";
import { createCartEndpoint } from "./endpoints/cart.endpoints";
import { createCustomerEndpoints } from "./endpoints/customer.endpoints";
import { createMenuEndpoints } from "./endpoints/menu.endpoints";
import { createNewsEndpoints } from "./endpoints/news.endpoints";
import { createProductEndpoints } from "./endpoints/product.endpoint";
import { createSetupEndpoints } from "./endpoints/setup.endpoints";
import { createStoreEndpoints } from "./endpoints/store.endpoints";
import { storeLocatorSearchEndpoint } from "./endpoints/storeLocatorSearch.endpoints";
import { createWidgetEndpoint } from "./endpoints/widget.endpoints";
import { createZendeskEndpoints } from "./endpoints/zendesk.endpoints";
import { createGameParticipantEndpoints } from "./endpoints/gameParticipant.endpoint";
import { createContactEndpoints } from "./endpoints/contact.endpoints";

export type RollingStartEndpointBuilder = EndpointBuilder<
  typeof baseQueryFn,
  never,
  "rollingStartApi"
>;

export const rolligStartApi = createApi({
  reducerPath: "rollingStartApi",
  baseQuery: baseQueryFn,
  endpoints: (builder) => ({
    ...createCartEndpoint(builder),
    ...storeLocatorSearchEndpoint(builder),
    ...createStoreEndpoints(builder),
    ...createZendeskEndpoints(builder),
    ...createWidgetEndpoint(builder),
    ...createCustomerEndpoints(builder),
    ...createSetupEndpoints(builder),
    ...createProductEndpoints(builder),
    ...createAuthEndpoints(builder),
    ...createNewsEndpoints(builder),
    ...createMenuEndpoints(builder),
    ...createGameParticipantEndpoints(builder),
    ...createContactEndpoints(builder),
    postJobWidget: builder.query<
      {
        connectionStatus?: boolean;
        created?: boolean;
        field_missing?: boolean;
      },
      {
        body: {
          lastname: string;
          firstname: string;
          address: string;
          city: string;
          email: string;
          id: string;
          phone: string;
          message: string;
          brand: string;
        };
      }
    >({
      query: ({ body }) => ({
        url: "jobwidget",
        method: "POST",
        body,
      }),
    }),

    //
    getHybridRouting: builder.query<IFrontController[], void>({
      keepUnusedDataFor: 60,
      query: () => ({
        url: "/hybridrouting",
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          dispatch(setFrontController(res.data));
        } catch (error) {}
      },
    }),

    getAddresses: builder.query<IAddressData[], void>({
      query: () => ({
        url: "/address",
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          dispatch(setAddresses(res.data));
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useLazyPostSigninQuery,
  useLazyGetCurrentCustomerQuery,
  useGetCurrentCustomerQuery,
  useLazyGetProductByIdQuery,
  useLazyPostJobWidgetQuery,
  useGetHybridRoutingQuery,
  useLazySendSponsorshipByEmailQuery,
  useGetCartQuery,
  useLazyGetStoreLocatorSearchQuery,
  useLazyGetStoreClosureQuery,
  useLazyGetCartQuery,
  usePostCartDeliveryOptionsMutation,
  useLazyGetAddressesQuery,
  useLazyGetStoreAvisVerifieQuery,
  usePostZendeskContactMutation,
  useLazyGetZendeskSearchQuery,
  useLazyGetWidgetInstanceQuery,
  usePostLikeProductMutation,
  useLazyGetSetupPrivateQuery,
  useLazyGetHybridRoutingQuery,
  useLazyGetSetupV2Query,
  useAddProductToCurrentCartMutation,
  usePostSignoutMutation,
  useDeleteProductFromCurrentCartMutation,
  useAddDiscountMutation,
  useRemoveDiscountMutation,
  usePostDiscountFreeProductMutation,
  useLazyGetNewsFlagItemQuery,
  useLazyGetNewsItemQuery,
  useLazyGetNewsCollectionQuery,
  useLazyGetMenuCollectionQuery,
  usePostRegisterNewsLetterMutation,
  useLazyPostSignInFacebookQuery,
  useLazyPostSignInGoogleQuery,
  useLazyPostNewRegisterNewsLetterQuery,
  useLazyPostRegisterQuery,
  useLazyPostCartReorderQuery,
  useLazyGetStockoutProductQuery,
  useLazyGetStoreItemQuery,
  useAddGameParticipantMutation,
  useUpdateContactMutation,
} = rolligStartApi;
