import styled from "styled-components";
import { TypographyScale } from "../../Foundation/Typography/Typography.styles";
import { useTheme } from "../../Themes/defaultTheme";
import { EInputState, EInputType, EInputVariant, InputProps } from "./Input";

interface _InputRootProps {
  $maxWidth?: number;
  $type: InputProps["type"];
  $state: EInputState;
  $variant: EInputVariant;
  $hasFocus: boolean;
  $error?: boolean;
}

export const _InputRoot = styled.div<_InputRootProps>(
  ({ $maxWidth, $type, $state, $variant, $hasFocus, $error }) => {
    const { color } = useTheme();
    return {
      display: "flex",
      alignItems: "center",
      gap: 16,
      width: "100%",
      maxWidth: $maxWidth,
      // minWidth: 300,
      height: 48,
      padding: "12px 16px",
      borderRadius: 48,
      border: `1px solid ${$error ? color.error : color.inputBorder}`,
      boxSizing: "border-box",
      overflow: "hidden",
      position: $type === "file" ? "relative" : undefined,
      transition: "background-color 0.3s",
      ...($state === EInputState.ERROR && {
        borderColor: color.error,
      }),
      ...($variant === EInputVariant.FILL && {
        backgroundColor: "rgba(255, 255,255, 0.6)",
        ...($hasFocus && {
          backgroundColor: "rgba(255, 255,255, 1)",
        }),
      }),

      "&.multiline": {
        height: "unset",
        minHeight: 48,
        alignItems: "flex-start",
        "&.has-more-lines": {
          borderRadius: 16,
        },
      },
      ["> svg"]: {
        position: "relative",
        pointerEvents: "none",
        zIndex: 1,
      },
      ...($type === EInputType.PASSWORD && {
        [".pwd-btn-eye"]: {
          padding: "8px",
          display: "flex",
          margin: "0 -8px 0 0",
        },
      }),

      ["& .input, & label"]: {
        background: "transparent",
        border: "none",
        outline: "none",
        height: "100%",
        minHeight: 46,
        flex: "1",
        padding: "4px 16px 6px 56px",
        margin: "0 -16px 0 -56px",
        textAlign: "left",

        "&.multiline": {
          padding: "0px 0px 0px 56px",
          marginRight: 0,
          flex: "unset",
          resize: "vertical",
          marginTop: -22,
          paddingTop: 16,
          height: 46,
          "&::after": {
            display: "none",
          },
        },

        /* Firefox */
        ["&[type=number]"]: {
          "-moz-appearance": "textfield",
        },
        ["> label"]: {
          position: "absolute",
          right: 0,
          top: 0,
          width: "100%",
          textAlign: "left",
          cursor: "pointer",
          margin: 0,
        },

        // file
        ["&[type=file]"]: {
          opacity: 0,
          visibility: "hidden",
          position: "absolute",
          padding: "4px 0px 6px 0px",
          left: 0,
          width: "100%",
          margin: 0,
          ["& + label"]: {
            cursor: "pointer",
          },
        },

        lineHeight: "30px",
        ["&::placeholder"]: {
          color: color.placeholder,
          opacity: 1,
        },
        ["&::-ms-input-placeholder"]: {
          color: color.placeholder,
        },
        ["&:-ms-input-placeholder"]: {
          color: color.placeholder,
        },

        ["&:autofill, &:-webkit-autofill, &:-webkit-autofill::first-line"]: {
          color: color.placeholder,
          background: "transparent !important",
        },
        "&:-internal-autofill-selected": {
          backgroundColor: "transparent",
        },
        ["&::-webkit-outer-spin-button, &::-webkit-inner-spin-button"]: {
          "-webkit-appearance": "none",
          margin: 0,
        },
      },
    };
  }
);

export const _InputWrapper = styled.div((props) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 12,
  };
});

export const _InputHtml = styled.input(() => {
  return {
    ...TypographyScale.button,
  };
});
